<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>

                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-digital-tachograph fa-md mr-1"></i> <span class="h5"> Gestión de check list stage 2</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">
                                    <b-tabs v-model="tabIndex" variant="pills">
                                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(datosGenerales)">
                                                <b-tab>
                                                    <template slot="title">
                                                        <i class="fas fa-server fa-md"></i> Datos generales
                                                    </template>

                                                    <b-row class="mt-3">
                                                        <b-col md="4">
                                                            <!-- <b-button class="btn btn-info float-right" size="sm"><i class="fas fa-users " /></b-button> -->
                                                            <!-- <i class="fas fa-users float-right " /> -->
                                                            <validation-provider name="normas" rules="required" v-slot="{errors}">
                                                                <!-- <b-button> -->
                                                                <b-form-group label="Normas:" class="mb-2">
                                                                    <v-select multiple @input="checkNormas" :reduce="listaNormas => listaNormas.idNorma" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCheckList.normas.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCheckList.normas" :options="listaNormas">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                                <!-- </b-button> -->
                                                            </validation-provider>
                                                        </b-col>
                                                        <!-- <b-col md="8">
                                                            <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Cliente:" class="mb-2">
                                                                    <v-select :reduce="listaClientes => listaClientes.idAuditoria" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCheckList.idAuditoria  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCheckList.idAuditoria" :options="listaClientes">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col> -->

                                                        <b-col md="6">
                                                            <validation-provider name="número de contrato" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                                <b-form-group label="Número de contrato:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número de contrato" v-model.lazy="datosCheckList.numeroContrato"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="auditoría actual" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                                <b-form-group label="Auditoria actual" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la auditoría actual" v-model.lazy="datosCheckList.auditoriaActual"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6" v-if="disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                            <validation-provider name="auditoría previa" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                                <b-form-group label="Auditoria previa" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la auditoría previa" v-model.lazy="datosCheckList.auditoriaPrevia"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col md="6">
                                                            <validation-provider name="producción / procesos de servicio" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                                <b-form-group label="Producción / Procesos de servicio:" class="mb-2">
                                                                    <v-select multiple :reduce="listaProduccionProceso => listaProduccionProceso.idProduccionProceso" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCheckList.produccion.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCheckList.produccion" :options="listaProduccionProceso">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6" v-if="disabledNorma4 || disabledNorma2 || disabledNorma3 ">
                                                            <validation-provider name="líder del equipo de auditoría" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                                <b-form-group label="Líder del equipo de auditoría:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el líder del equipo de auditoría" v-model.lazy="datosCheckList.liderEquipoAuditoria"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6" v-if="disabledNorma4 || disabledNorma2 || disabledNorma3 ">
                                                            <validation-provider name="auditor" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                                <b-form-group label="Auditor" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el auditor" v-model.lazy="datosCheckList.auditor"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="tipo de auditoría" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                                <b-form-group label="Tipo de auditoría:" class="mb-2">
                                                                    <v-select multiple :reduce="listaTipoAuditoria => listaTipoAuditoria.idTipoAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosCheckList.tipoAuditoria.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosCheckList.tipoAuditoria" :options="listaTipoAuditoria">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="alcance de la certificacion " :rules="{ }" v-slot="validationContext">
                                                                <b-form-group label="Alcance de la certificacion:" class="mb-2">
                                                                    <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el alcance de la certificacion" v-model.lazy="datosCheckList.alcanceCertificacion"></b-form-textarea>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6" v-if=" disabledNorma5 || disabledNorma6">
                                                            <validation-provider name="declaración de aplicabilidad " :rules="{ }" v-slot="validationContext">
                                                                <b-form-group label="Declaración de aplicabilidad:" class="mb-2">
                                                                    <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la declaración de aplicabilidad" v-model.lazy="datosCheckList.declaracionAplicabilidad"></b-form-textarea>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12" v-if="disabledNorma4 || disabledNorma2 || disabledNorma3 ">
                                                            <b-table-simple bordered show-empty mediun responsive outlined>
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="40%">
                                                                            <span><b>Pautas para completar la lista de verificación:</b></span>
                                                                        </b-td>
                                                                        <b-td colspan="2">
                                                                            <b-form-group class="mb-2">
                                                                                <b-form-input placeholder="Cada auditor deberá completar una lista de verificación para los respectivos procesos auditados. " v-model.lazy="datosCheckList.pautas[0].texto"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>

                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td>
                                                                            <b-form-group class="mb-2">
                                                                                <b-form-input placeholder="Proporcione el nombre de inaRegional" v-model.lazy="datosCheckList.pautas[1].texto"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td>
                                                                            <b-form-group class="mb-2">
                                                                                <b-form-input placeholder="Proporcionar función auditada" v-model.lazy="datosCheckList.pautas[2].texto"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td width="40%">
                                                                            <b-form-group class="mb-2">
                                                                                <b-form-input placeholder="Auditar todos los procesos según el plan de auditoría para una implementación efectiva" v-model.lazy="datosCheckList.pautas[3].texto"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="3">
                                                                            <b-form-group class="mb-2">
                                                                                <b-form-input placeholder="Número de documento y número de rev. De cada proceso si aplica" v-model.lazy="datosCheckList.pautas[4].texto"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>

                                                        <b-col md="12" class="mb-2" v-if="disabledNorma5 || disabledNorma6 || disabledNorma1">
                                                            <b-table-simple bordered show-empty mediun responsive outlined>

                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td>
                                                                            <b-form-group label="Auditor principal:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el auditor principal" v-model.lazy="datosCheckList.datosEquipoAuditor[0].auditorPrincipal"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td>
                                                                            <b-form-group label="Auditor:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el auditor" v-model.lazy="datosCheckList.datosEquipoAuditor[0].auditor1"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td>
                                                                            <b-form-group label="Auditor:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el auditor" v-model.lazy="datosCheckList.datosEquipoAuditor[0].auditor2"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td>
                                                                            <b-form-group label="Becario de auditor IQC:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el vecario de auditor" v-model.lazy="datosCheckList.datosEquipoAuditor[0].vecarioAuditor"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td>
                                                                            <b-form-group label="Observador de auditoría de IQC:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el observador de auditoría" v-model.lazy="datosCheckList.datosEquipoAuditor[0].observadorAuditoria"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>

                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td>
                                                                            <b-form-group label="Auditor:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el auditor" v-model.lazy="datosCheckList.datosEquipoAuditor[0].auditor3"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td>
                                                                            <b-form-group label="Auditor:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el auditor" v-model.lazy="datosCheckList.datosEquipoAuditor[0].auditor4"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td>
                                                                            <b-form-group label="Auditor:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el auditor" v-model.lazy="datosCheckList.datosEquipoAuditor[0].auditor5"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td colspan="2">
                                                                            <b-form-group label="Observador AB:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el observador AB" v-model.lazy="datosCheckList.datosEquipoAuditor[0].observadorAB"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>
                                                        <b-col md="12" v-if="disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                            <b-form-group class="mb-2">
                                                                <quill-editor v-model="datosCheckList.indicaciones[0].texto" :options="editorOption">
                                                                </quill-editor>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md="12" v-if="disabledNorma2 || disabledNorma3 || disabledNorma4">
                                                            <b-form-group class="mb-2">
                                                                <quill-editor v-model="datosCheckList.indicaciones[1].texto" :options="editorOption">
                                                                </quill-editor>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button :to="{name: $route.params.eu ? 'ejecutar auditoria' : 'Documentos Stage 2' }" class="mr-2" variant="dark" type="button">
                                                                <i class="fas fa-arrow-left"></i> Volver
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer2" v-if="disabledNorma1" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion1)">
                                                <b-tab :title-item-class="(datosCheckList.idCheckList=='' && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> ISO 9001
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mt-3">
                                                        <b-col md="12">
                                                            <b-table-simple bordered show-empty mediun responsive outlined>
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="40%" class="text-center" colspan="2" rowspan="2">
                                                                            <b>ISO 9001: 2015 Sistemas de gestión de calidad</b>
                                                                        </b-td>
                                                                        <b-td width="40%" class="text-center" rowspan="2">
                                                                            <p align="justify">(Detalles de la auditoría de la etapa II para la evaluación inicial, la recertificación y la auditoría de vigilancia)
                                                                                Proporcionar referencia de número de documento: detalles de la muestra para cada uno de los procesos auditados y evidencia objetiva para respaldar la implementación del proceso
                                                                            </p>
                                                                        </b-td>
                                                                        <b-td width="20%" class="text-center" colspan="4">
                                                                            Conclusión
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="5%" class="text-center">
                                                                            A
                                                                        </b-td>
                                                                        <b-td width="5%" class="text-center">
                                                                            NCR-MAYOR
                                                                        </b-td>
                                                                        <b-td width="5%" class="text-center">
                                                                            NCR-MENOR
                                                                        </b-td>
                                                                        <b-td width="5%" class="text-center">
                                                                            <p>OM
                                                                                Justificación para clasificar los hallazgos de auditoría como OM</p>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="2" class="text-center">
                                                                            <b-form-group label="Reunión de apertura realizada en:">
                                                                                <b-form-checkbox-group plain v-model="datosCheckList.c9001[0].header[0].check" :options="datosCheckList.c9001[0].header[0].options" />
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[0].input1  = $event" :value="datosCheckList.c9001[0].header[0].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td colspan="4" class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[0].input2  = $event" :value="datosCheckList.c9001[0].header[0].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="2" class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[1].texto  = $event" :value="datosCheckList.c9001[0].header[1].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[1].input1  = $event" :value="datosCheckList.c9001[0].header[1].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td colspan="4" class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[1].input2  = $event" :value="datosCheckList.c9001[0].header[1].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr v-for="data in datosCheckList.c9001[0].body" :key="data.index">
                                                                        <b-td v-if="data.hasCategoria" :rowspan="data.size" width="15%" class="valign-middle text-center">
                                                                            <b-form-textarea rows="2" class="mb-2" max-rows="6" @change="data.categoria  = $event" :value="data.categoria"></b-form-textarea>
                                                                            <b-form-checkbox-group class="mb-2" v-model.lazy="data.select" :options="[{ value: 1, text: 'En el sitio' },{ value: 2, text: 'Auditoría remota' },]" small plain></b-form-checkbox-group>
                                                                            <b-form-group label="Auditado:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el auditado" :value="data.auditado" @change="data.auditado = $event"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td :colspan="data.hasSubCheck ? '6' : '1'" class="text-center">
                                                                            <div v-if="data.hasSubTexto">
                                                                                <b-form-group :label="`${data.label1}:`" class="mb-2">
                                                                                    <b-form-input :placeholder="`Ingrese ${data.placeholder1}`" :value="data.texto1" @change="data.texto1 = $event"></b-form-input>
                                                                                </b-form-group>
                                                                                <b-form-group :label="`${data.label2}:`" class="mb-2">
                                                                                    <b-form-input :placeholder="`Ingrese ${data.placeholder2}`" :value="data.texto2" @change="data.texto2 = $event"></b-form-input>
                                                                                </b-form-group>
                                                                            </div>
                                                                            <div v-else-if="data.hasTexto">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                            </div>
                                                                            <div v-else-if="data.hasSubCheck">
                                                                                <b-form-checkbox v-model="data.subCheck" plain value="1" unchecked-value="0">
                                                                                    {{data.labelSubCheck}}
                                                                                </b-form-checkbox>
                                                                            </div>
                                                                        </b-td>
                                                                        <template v-if="!data.hasSubCheck">
                                                                            <b-td class="text-center">
                                                                                <!-- <quill-editor spell-check="false" :options="editorOption" v-model="data.detalles">
                                                                        </quill-editor> -->
                                                                                <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aqui..." @change="data.detalles  = $event" :value="data.detalles"></b-form-textarea>
                                                                                <!-- <Editor :api-key="keyInit" :init="optionsInit"  @change="alerta($event)" :value="data.detalles"/> -->
                                                                            </b-td>
                                                                            <b-td class="text-right">
                                                                                <b-form-checkbox-group v-model="data.check" plain>
                                                                                    <b-form-checkbox value="1" />
                                                                                </b-form-checkbox-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox-group v-model="data.check" plain>
                                                                                    <b-form-checkbox value="2" />
                                                                                </b-form-checkbox-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox-group v-model="data.check" plain>
                                                                                    <b-form-checkbox value="3" />
                                                                                </b-form-checkbox-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox-group v-model="data.check" plain>
                                                                                    <b-form-checkbox value="4" />
                                                                                </b-form-checkbox-group>
                                                                            </b-td>
                                                                        </template>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="7" class="text-center">
                                                                            <b-form-checkbox-group plain v-model="datosCheckList.c9001[0].footer[0].checks" :options="datosCheckList.c9001[0].footer[0].options" />
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer3" v-if="disabledNorma2 || disabledNorma3" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion2)">
                                                <b-tab :title-item-class="(datosCheckList.idCheckList==''  && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> ISO 14001 - 45001
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mt-3">
                                                        <b-col md="12">
                                                            <b-table-simple bordered show-empty mediun responsive outlined>
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="40%" class="text-center" colspan="2">
                                                                            <b>ISO 14001: 2015 - ISO 45001: 2018 </b>
                                                                        </b-td>
                                                                        <b-td width="30%" class="text-center">
                                                                            <p align="justify">Detalles de las muestras auditadas en cada proceso según corresponda</p>
                                                                        </b-td>
                                                                        <b-td width="30%" class="text-center" colspan="4">
                                                                            Conclusión
                                                                        </b-td>
                                                                    </b-tr>

                                                                    <b-tr v-for="data in datosCheckList.c145001[0].body" :key="data.index">
                                                                        <b-td v-if="data.hasCategoria" :rowspan="data.size" width="15%" class="valign-middle text-center">
                                                                            <b-form-textarea rows="2" class="mb-2" max-rows="6" @change="data.categoria  = $event" :value="data.categoria"></b-form-textarea>
                                                                            <b-form-group v-if="data.hasAuditado" label="Auditado:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el auditado" :value="data.auditado" @change="data.auditado = $event"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td :colspan="data.hasSubCheck ? '4' : '1'" class="text-center">
                                                                            <div v-if="data.hasTexto">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                            </div>
                                                                            <div v-else-if="data.hasSubCheck">
                                                                                <b-form-checkbox v-model="data.subCheck" plain value="1" unchecked-value="0">
                                                                                    {{data.labelSubCheck}}
                                                                                </b-form-checkbox>
                                                                            </div>
                                                                        </b-td>
                                                                        <template v-if="!data.hasSubCheck">
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" class="mb-2" max-rows="6" placeholder="Escriba aqui..." @change="data.detalles  = $event" :value="data.detalles"></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" class="mb-2" max-rows="6" placeholder="Escriba aqui..." @change="data.resultado  = $event" :value="data.resultado"></b-form-textarea>
                                                                            </b-td>
                                                                        </template>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer4" v-if="disabledNorma4" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion3)">
                                                <b-tab :title-item-class="(datosCheckList.idCheckList=='' && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> ISO 37001
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mt-3">
                                                        <b-col md="12">
                                                            <b-table-simple bordered show-empty mediun responsive outlined>
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="40%" class="text-center" colspan="2">
                                                                            <b>ISO 37001: 2016 Sistemas de gestión contra el soborno</b>
                                                                        </b-td>
                                                                        <b-td width="30%" class="text-center">
                                                                            <p align="justify">Detalles de las muestras auditadas en cada proceso según corresponda</p>
                                                                        </b-td>
                                                                        <b-td width="30%" class="text-center" colspan="4">
                                                                            Resultados de la auditoría
                                                                        </b-td>
                                                                    </b-tr>

                                                                    <b-tr v-for="data in datosCheckList.c37001[0].body" :key="data.index">
                                                                        <b-td v-if="data.hasCategoria" :rowspan="data.size" :colspan="data.fullWidth ? '4': '1'" width="15%" class="valign-middle text-center">
                                                                            <template v-if="data.hasAuditado">
                                                                                <b-form-group label="Auditado:" class="mb-2">
                                                                                    <b-form-input placeholder="Ingrese el auditado" :value="data.auditado" @change="data.auditado = $event"></b-form-input>
                                                                                </b-form-group>
                                                                            </template>
                                                                            <template v-else-if="data.hasSubCheck">
                                                                                <b-form-checkbox v-model="data.subCheck" plain value="1" unchecked-value="0">
                                                                                    {{data.labelSubCheck}}
                                                                                </b-form-checkbox>
                                                                            </template>
                                                                            <template v-else>
                                                                                <b-form-textarea rows="2" class="mb-2" max-rows="6" @change="data.categoria  = $event" :value="data.categoria"></b-form-textarea>
                                                                            </template>
                                                                        </b-td>
                                                                        <template v-if="!data.fullWidth">
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" class="mb-2" max-rows="6" placeholder="Escriba aqui..." @change="data.detalles  = $event" :value="data.detalles"></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" class="mb-2" max-rows="6" placeholder="Escriba aqui..." @change="data.resultado  = $event" :value="data.resultado"></b-form-textarea>
                                                                            </b-td>
                                                                        </template>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer5" v-if="disabledNorma5 || disabledNorma6" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion4)">
                                                <b-tab :title-item-class="(datosCheckList.idCheckList=='' && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> ISO 27001
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mt-3">
                                                        <b-col md="12">
                                                            <b-table-simple bordered show-empty mediun responsive outlined>
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="40%" class="text-center" colspan="2" rowspan="2">
                                                                            <b>ISO 27001: 2013 Sistemas de gestión de seguridad de la información</b>
                                                                        </b-td>
                                                                        <b-td width="40%" class="text-center" rowspan="2">
                                                                            <p align="justify">(Detalles de la auditoría de la etapa II para la evaluación inicial, la recertificación y la auditoría de vigilancia)
                                                                                Proporcionar referencia de número de documento: detalles de la muestra para cada uno de los procesos auditados y evidencia objetiva para respaldar la implementación del proceso. </p>
                                                                        </b-td>
                                                                        <b-td width="20%" class="text-center" colspan="4">
                                                                            Conclusión
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="5%" class="text-center">
                                                                            A
                                                                        </b-td>
                                                                        <b-td width="5%" class="text-center">
                                                                            NCR-MAYOR
                                                                        </b-td>
                                                                        <b-td width="5%" class="text-center">
                                                                            NCR-MENOR
                                                                        </b-td>
                                                                        <b-td width="5%" class="text-center">
                                                                            <p>OM</p>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="2" class="text-center">
                                                                            <b-form-group label="Reunión de apertura realizada en:">
                                                                                <b-form-checkbox-group plain v-model="datosCheckList.c9001[0].header[0].check" :options="datosCheckList.c9001[0].header[0].options" />
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[0].input1  = $event" :value="datosCheckList.c9001[0].header[0].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td colspan="4" class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[0].input2  = $event" :value="datosCheckList.c9001[0].header[0].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="2" class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[1].texto  = $event" :value="datosCheckList.c9001[0].header[1].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[1].input1  = $event" :value="datosCheckList.c9001[0].header[1].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td colspan="4" class="text-center">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="datosCheckList.c9001[0].header[1].input2  = $event" :value="datosCheckList.c9001[0].header[1].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr v-for="data in datosCheckList.c27001[0].body" :key="data.index">
                                                                        <b-td v-if="data.hasCategoria" :rowspan="data.size" :colspan="data.widthRowOne ? data.widthRowOne  : '1'" width="15%" class="valign-middle text-center">
                                                                            <b-form-textarea rows="2" class="mb-2" max-rows="6" @change="data.categoria  = $event" :value="data.categoria"></b-form-textarea>
                                                                            <b-form-checkbox-group v-if="!data.hasnSelect" class="mb-2" v-model.lazy="data.select" :options="[{ value: 1, text: 'En el sitio' },{ value: 2, text: 'Auditoría remota' },]" small plain></b-form-checkbox-group>
                                                                            <b-form-group v-if="!data.hasnAuditado" label="Auditado:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese el auditado" :value="data.auditado" @change="data.auditado = $event"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td class="text-center" v-if="!data.hasnTwo" :colspan="data.widthRowTwo ? data.widthRowTwo : '1'">
                                                                            <div v-if="data.hasSubTexto">
                                                                                <b-form-group :label="`${data.label1}:`" class="mb-2">
                                                                                    <b-form-input :placeholder="`Ingrese ${data.placeholder1}`" :value="data.texto1" @change="data.texto1 = $event"></b-form-input>
                                                                                </b-form-group>
                                                                            </div>
                                                                            <div v-else-if="data.hasTexto">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                            </div>
                                                                            <div v-else-if="data.hasSubCheck">
                                                                                <b-form-checkbox v-model="data.subCheck" plain value="1" unchecked-value="0">
                                                                                    {{data.labelSubCheck}}
                                                                                </b-form-checkbox>
                                                                            </div>
                                                                        </b-td>
                                                                        <template v-if="!data.hasnDetalles">
                                                                            <b-td class="text-center">
                                                                                <!-- <quill-editor spell-check="false" :options="editorOption" v-model="data.detalles">
                                                                                </quill-editor> -->
                                                                                <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aqui..." @change="data.detalles  = $event" :value="data.detalles"></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-right">
                                                                                <b-form-checkbox-group v-model="data.check" plain>
                                                                                    <b-form-checkbox value="1" />
                                                                                </b-form-checkbox-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox-group v-model="data.check" plain>
                                                                                    <b-form-checkbox value="2" />
                                                                                </b-form-checkbox-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox-group v-model="data.check" plain>
                                                                                    <b-form-checkbox value="3" />
                                                                                </b-form-checkbox-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox-group v-model="data.check" plain>
                                                                                    <b-form-checkbox value="4" />
                                                                                </b-form-checkbox-group>
                                                                            </b-td>
                                                                        </template>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="7" class="text-center">
                                                                            <b-form-checkbox-group plain v-model="datosCheckList.c27001[0].footer[0].checks" :options="datosCheckList.c27001[0].footer[0].options" />
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer6" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(registrarCheckList)">
                                                <b-tab :title-item-class="(datosCheckList.idCheckList==''  && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> Guardar cambios
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-save"></i> Guardar
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>

                                                        </b-row>
                                                    </div>

                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                    </b-tabs>

                                    <!-- <b-row v-if="$route.params.id" class="text-center mt-2">
                                        <b-col md="12">
                                            <b-button class="mr-2" variant="dark" :to="{name: 'Ingreso de documentos Stage 2'}" type="button">
                                                <i class="fas fa-arrow-left"></i> Volver
                                            </b-button>
                                        </b-col>
                                    </b-row> -->

                                    <!--  </b-form> -->
                                    <!-- </validation-observer> -->
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
    quillEditor
} from 'vue-quill-editor'
// import Editor from '@tinymce/tinymce-vue'

export default {
    components: {
        quillEditor,
        // Editor
    },
    data() {
        return {
            isTemplate: true,
            // keyInit: 'qze5q9wcqsvqt06k6nz0et5cww9uqu6sx11n37dk5t830bar',
            // optionsInit: {
            //     selector: 'textarea',
            //     plugins: "emoticons hr image link lists charmap table",
            //     toolbar: "formatgroup paragraphgroup insertgroup",
            //     toolbar_groups: {
            //         formatgroup: {
            //             icon: 'format',
            //             tooltip: 'Formatting',
            //             items: 'bold italic underline strikethrough | forecolor backcolor | superscript subscript | removeformat'
            //         },
            //         paragraphgroup: {
            //             icon: 'paragraph',
            //             tooltip: 'Paragraph format',
            //             items: 'h1 h2 h3 | bullist numlist | alignleft aligncenter alignright | indent outdent'
            //         },
            //         insertgroup: {
            //             icon: 'plus',
            //             tooltip: 'Insert',
            //             items: 'link image emoticons charmap hr'
            //         }
            //     },
            //     skin: 'outside',
            //     toolbar_location: 'top',
            //     menubar: false
            // },
            editorOption: {
                // theme: 'bubble',
                placeholder: 'Escriba aqui...',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        /* ['blockquote', 'code-block'], */
                        [{
                            'header': 1
                        }, {
                            'header': 2
                        }],
                        [{
                            'list': 'ordered'
                        }, {
                            'list': 'bullet'
                        }],
                        [{
                            'script': 'sub'
                        }, {
                            'script': 'super'
                        }],
                        [{
                            'indent': '-1'
                        }, {
                            'indent': '+1'
                        }],
                        [{
                            'direction': 'rtl'
                        }],
                        [{
                            'size': ['small', false, 'large', 'huge']
                        }],
                        [{
                            'header': [1, 2, 3, 4, 5, 6, false]
                        }],
                        [{
                            'font': []
                        }],
                        [{
                            'color': []
                        }, {
                            'background': []
                        }],
                        [{
                            'align': []
                        }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],
                    syntax: {
                        highlight: text => hljs.highlightAuto(text).value
                    }
                }
            },
            datosCheckList: {
                idCheckList: '',
                idCliente: null,

                //DatosGenerales
                idAuditoria: null,
                normas: [],
                numeroContrato: '',
                auditoriaActual: '',
                auditoriaPrevia: '',
                produccion: [],
                tipoAuditoria: [],
                declaracionAplicabilidad: '',
                alcanceCertificacion: '',
                liderEquipoAuditoria: '',
                auditor: '',

                indicaciones: [{
                    texto: '<p class="ql-align-justify">Nota (S) para la auditoría de la etapa II: Proporcione el nombre del auditado y la función auditada: audite todos los procesos para la auditoría de la etapa II y los procesos planificados para las auditorías de vigilancia según el plan de auditoría para una implementación efectiva y proporcione evidencia con ejemplos de respaldo para cada proceso. Si alguna de las cláusulas no es aplicable, indíquelo como N / A</p><p><u>(Indique la conclusión como: A - Accptence / MA - Major NCR / MI - Minor NCR &amp; OFI - Opprutines for Improvments)</u></p><p><strong>La justificación para clasificar los hallazgos de auditoría como OFI debe ser proporcionada por el auditor asignado</strong></p>'
                }, {
                    texto: '<p><strong>Identificación de muestra para cada uno de los procesos auditados como evidencia objetiva para sustentar la implementación del proceso y el alcance de la certificación. NOTA:</strong>La identificación de la muestra también se puede proporcionar en el informe de evaluación, pero debe estar respaldada por el nombre del auditado, la función auditada: el número de documento y el número de rev. De cada proceso, si corresponde, se deben proporcionar en la lista de verificación junto con la indicación de la conclusión como A o MA o MI u OFI según corresponda</p><p><strong>Capture el nombre y la designación de otros miembros del personal entrevistados (personal de oficina y del sitio)</strong></p><p>Indique la conclusión como: A - Accptence / MA - Major NCR / MI - Minor NCR &amp; OFI - Opprutines for Improvments</p><p>Si alguna de las cláusulas no es aplicable, indicar como N / A</p><p><strong>Indique los hallazgos de la auditoría como</strong>: A - Accptence / MA - Major NCR / MI - Minor NCR y OFI - Opprutines for Improvement </p><p><strong>Los hallazgos de auditoría, que son no conformidades, no deben registrarse como oportunidades de mejora a menos que estén respaldados por una justificación (</strong>ISO 17021-1: 2015 cl 9.4.5.2)</p><p> </p>'
                }],

                pautas: [{
                    texto: 'Cada auditor deberá completar una lista de verificación para los respectivos procesos auditados. ',
                }, {
                    texto: 'Proporcione el nombre del auditado',
                }, {
                    texto: 'Proporcionar función auditada',
                }, {
                    texto: 'Auditar todos los procesos según el plan de auditoría para una implementación efectiva',
                }, {
                    texto: 'Número de documento y número de rev. De cada proceso si aplica',
                }],

                datosEquipoAuditor: [{
                    auditorPrincipal: '',
                    auditor1: '',
                    auditor2: '',
                    vecarioAuditor: '',
                    observadorAuditoria: '',
                    auditor3: '',
                    auditor4: '',
                    auditor5: '',
                    observadorAB: '',
                }],

                c9001: [{
                    header: [{
                        check: [],
                        options: [{
                                text: 'Oficina registrada',
                                value: '1'
                            },
                            {
                                text: 'Planta',
                                value: '2'
                            },
                            {
                                text: 'Oficina (es) regional (es)',
                                value: '3'
                            },
                            {
                                text: 'Sucursal (es)',
                                value: '4'
                            }
                        ],
                        input1: '',
                        input2: '',
                    }, {

                        texto: 'Visita a la planta',
                        input1: '',
                        input2: '',
                    }, ],
                    body: [{
                            hasCategoria: true,
                            categoria: 'Alta Dirección',
                            auditado: '',
                            size: "13",
                            select: [],
                            hasTexto: true,
                            texto: 'Compromiso de gestión',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasTexto: true,
                            texto: 'Propósito de la certificación QMS',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasTexto: true,
                            texto: 'Requisitos legales aplicables y actualización de los requisitos',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasTexto: true,
                            texto: 'Alcance del SGC (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Política y comunicación del SGC (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos del SGC y consecución de objetivos',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Efectividad del SGC',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Gestión de reclamaciones legales y de clientes (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Comunicación interna y externa ',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Fiabilidad de la revisión de la gestión y la auditoría interna ',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Provisión de recursos',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Desempeño comercial: otros problemas, si corresponde',
                            detalles: '',
                            check: [],
                        },

                        {
                            hasCategoria: true,
                            categoria: 'Representante de la dirección: función responsable del SGC implementación y coordinación',
                            auditado: '',
                            size: "16",
                            select: [],
                            hasTexto: true,
                            texto: 'Recurso de mano de obra el día de la auditoría (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        },
                        {
                            texto1: '',
                            label1: 'Tiempo completo',
                            placeholder1: 'el tiempo completo',
                            hasSubTexto: true,
                            texto2: '',
                            label2: 'Tiempo parcial',
                            placeholder2: 'el tiempo parcial',
                            detalles: '',
                            check: [],
                        }, {
                            texto1: '',
                            label1: 'Estacional',
                            placeholder1: 'estacional',
                            hasSubTexto: true,
                            texto2: '',
                            label2: 'Mano de obra total',
                            placeholder2: 'la mano de obra total',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Revisión del informe de auditoría anterior y cierre de NCR (s) / Observaciones / OFI (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Contexto de la organización y partes interesadas',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Gestión de la comunicación',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Procesos de SGC e información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Planificación - Riesgos y oportunidades - Eficacia de acciones para riesgos y oportunidades - Objetivos de calidad - Planificación para lograr objetivos',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Planificación de cambios en QMS',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Análisis de datos y evaluación del desempeño -  a.Análisis y evaluación de productos y servicios b.Conformidad - satisfacción del cliente c.Desempeño y efectividad del SGC d.Efectividad de acciones para riesgos y oportunidades - desempeño de proveedores externos e.Necesidad de mejorar el SGC ',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Auditoría interna - Planificación de la auditoría - Frecuencia de la auditoría - Criterios de auditoría - Alcance de la auditoría - Informar el resultado de la auditoría a la gerencia - Corrección y acción correctiva - Retención de información documentada (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Frecuencia de revisión por la dirección - Entrada de puntos de la agenda de MRM - Salida de MRM y Eficacia del sistema de gestión con respecto al logro de los objetivos y los resultados previstos. (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Gestión de no conformidades producto-proceso y sistema, Quejas de clientes - Acción correctiva (obligatoria para cada auditoría)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales y consecución de objetivos, Corrección y acción correctiva de las observaciones de Auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua considerando resultado de auditoría interna-MRM- resultados de evaluación (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Uso de marcas de certificación y acreditación (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Recursos humanos / formación',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Estructura organizativa, roles, responsabilidad, autoridad - Gestión de competencias, plan de formación y cumplimiento - conciencia',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Mantenimiento',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Mantenimiento de Infraestructura - Control de calibración de activos operativos - instrumentos, si aplica - retención de información documentada',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasCategoria: true,
                            categoria: 'Comercial y contratos',
                            auditado: '',
                            size: "5",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisión de la consulta y el contrato del cliente - Identificación del contrato / trabajo - cambios en el contrato - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Actividades posteriores a la entrega (Garantía - Garantía - Devolución del producto - Reparación - Quejas del cliente)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Medición y evaluación de la satisfacción del cliente.',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Revisión de la propiedad suministrada por el cliente (materiales-planos-especificaciones)',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasCategoria: true,
                            categoria: 'Diseño',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisión de los datos e insumos del diseño del cliente - Diseño del producto / servicio - calibración y validación del software de diseño, si corresponde - Control de los dibujos de ingeniería, incluidas las revisiones - propiedad proporcionada por el cliente (dibujos y especificaciones) - aprobación de la salida del diseño - revisión del diseño - validación del diseño - diseño verificación - aprobación del cliente, si se requiere - liberación de dibujos para producción - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasCategoria: true,
                            categoria: 'Ingenieria',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisión de los dibujos y datos de entrada del cliente - Control de los dibujos de ingeniería, incluidas las revisiones - Propiedad proporcionada por el cliente (Dibujos y especificaciones) - Revisión de los dibujos - Retención de la información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasCategoria: true,
                            categoria: 'Obtención',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Selección - Evaluación - reevaluación de proveedores externos - Mantener la lista de proveedores aprobados - Subcontratación - Comunicación con agencias externas - Retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasCategoria: true,
                            categoria: 'Gestión de tiendas',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Entorno para las tiendas - Control del almacenamiento y recepción / emisión de material - control de la vida útil de los artículos, si corresponde - Control de los materiales suministrados por el cliente - Despacho de productos - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales - Corrección y acción correctiva para las observaciones de auditoría - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasCategoria: true,
                            categoria: 'Provisión de producción / servicio',
                            auditado: '',
                            size: "7",
                            select: [],
                            subCheck: 0,
                            hasSubCheck: true,
                            labelSubCheck: 'a.	Auditado durante el horario laboral normal ya que el cliente trabaja solo en turno de día',
                        }, {
                            select: [],
                            subCheck: 0,
                            hasSubCheck: true,
                            labelSubCheck: 'b.	Auditado durante el horario laboral normal ya que los procesos son similares en cada turno',
                        }, {
                            select: [],
                            subCheck: 0,
                            hasSubCheck: true,
                            labelSubCheck: 'c.	Auditoría realizada durante el turno normal y parte del turno considerando diferentes procesos en cada turno.',
                        }, {
                            hasTexto: true,
                            texto: 'Planificación y control de la producción - entorno para la operación del proceso - control operativo y entorno para la producción / Operaciones de servicio',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Procesos de producción y servicio - Planificación de la producción - Validación de procesos - Identificación de contratos o trabajos - Almacenamiento de productos en proceso - Liberación de productos',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Control de cambios para producción o prestación de servicios',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales - Corrección y acción correctiva para las observaciones de auditoría - retención de información documentada',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Sitio del proyecto',
                            auditado: '',
                            size: "1",
                            select: [],
                            hasTexto: true,
                            texto: 'Alcance de los servicios en el sitio - Plan del proyecto - plan de movilización - control de los documentos de ingeniería, incluidos los planos - competencia del personal - Almacenamiento de materias primas - Inspección de materiales entrantes - control de la propiedad suministrada por el cliente - Equipo de protección personal - Instalación - puesta en servicio - inspección y pruebas - interfaz con clientes y representantes de clientes - retención de información documentada',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Seguro de Calidad / Control de Calidad',
                            auditado: '',
                            size: "3",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisar el plan de aseguramiento de la calidad / plan de inspección - revisar la interfaz con el cliente o el representante del cliente - inspección entrante - inspección en proceso - inspección del producto final - liberación del producto - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Calibración de recursos de monitoreo y medición - lista de instrumentos - identificación de instrumentos - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales - Corrección y acción correctiva para las observaciones de auditoría - retención de información documentada',
                            detalles: '',
                            check: [],
                        },
                    ],
                    footer: [{
                        check: [],
                        options: [{
                                text: 'Reunión de cierre en la oficina registrada',
                                value: '1'
                            },
                            {
                                text: 'Ubicación de la planta',
                                value: '2'
                            },
                            {
                                text: 'Oficina regional',
                                value: '3'
                            },
                            {
                                text: 'Sucursal',
                                value: '4'
                            }
                        ]
                    }]
                }, ],
                c145001: [{
                    body: [{
                            hasCategoria: true,
                            categoria: '1.0. Cima Gestión',
                            size: "9",
                            hasTexto: true,
                            texto: 'Compromiso de gestión',
                            detalles: '',
                            resultado: '',
                        },
                        {
                            hasTexto: true,
                            texto: 'Propósito de la certificación QHSE',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'Efectividad de QHSE',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'Comunicación interna y externa',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'Fiabilidad de la revisión de la gestión y la auditoría interna',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'Provisión de recursos',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'Cambios en el nivel de integración',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'Desempeño comercial: otros problemas, si corresponde',
                            detalles: '',
                            resultado: '',
                        },
                        {
                            hasCategoria: true,
                            categoria: '2.0. Representante de QHSE - o función responsable de los sistemas de gestión de QHSE',
                            size: "21",
                            hasTexto: true,
                            texto: '2.1. Recurso de mano de obra el día de la auditoría (obligatorio para cada auditoría)',
                            detalles: 'Proporcione detalles en el informe de evaluación.',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.2. Revisión del informe de auditoría anterior y cierre de NCR (s) / Observaciones u OFI (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.3. Alcance de QHSE MS (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.4. Política y comunicación de QHSE (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.5. Objetivos de QHSE y consecución de objetivos (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.6. Procesos de QHSE e información documentada',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.7. Auditoría interna - Planificación de la auditoría - Frecuencia de auditoría - criterios de auditoría - Alcance de la auditoría - Informar el resultado de la auditoría a la dirección - corrección y acción correctiva(obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.8. Revisión de gestión - Revisión de gestión Frecuencia - Entrada de punto de agenda MRM - Salida MRM y Eficacia del sistema de gestión con respecto al logro de los objetivos y los resultados previstos.(obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.9. Identificación de peligros y evaluación de riesgos y Oportunidades para actividades rutinarias y no rutinarias (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.10. Evaluación de aspectos e impacto - Ciclo de vidaperspectiva– Ambiental significativa aspectos (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        },
                        {
                            hasTexto: true,
                            texto: '2.11. Gestión de cambios relacionada con: lugar de trabajo Ubicaciones y alrededores - organización del trabajo - condiciones de trabajo - equipo - fuerza laboral - cambios en los requisitos legales y otros requisitos - conocimiento o información sobre peligros y riesgos de SST - desarrollos en el conocimiento y la tecnología(obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.12. Gestión de no conformidades - Incidencia Informes e investigación - Quejas de clientes - Quejas de autoridades legales - acciones correctivas (obligatorias para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.13. Programa de mejora continua (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.14. Uso de marcas de certificación y acreditación (Obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.15. Consulta y participación de los trabajadores - Trabajadores no gerenciales',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.16. Contexto de la organización: interno y externo cuestiones: expectativas de las partes interesadas',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.17. Riesgos y oportunidades considerando el contexto, partes interesadas y procesos QHSE -gestión del cambio',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.18. Determinación de requisitos legales actualizados - Frecuencia de evaluación - Evaluación del cumplimiento',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.19. Análisis y evaluación de datos apropiados y información - \na.	Implementación efectiva de sistemas de gestión QHSE \nb.	Número de incidentes, frecuencia de incidentes y tendencia \nc.	Porcentaje de acciones correctivas completadas a tiempo \nd.	Autoridades legales y reclamación de clientes \ne.	Cumplimiento de requisitos legales y de otro tipo. \nf.	Alcanzar los objetivos de QHSE \ng.	Efectividad de las acciones tomadas para abordar riesgos y oportunidades \nh.	Desempeño de proveedores externos\ni.	Necesidad de mejoras en el sistema de gestión de la calidad. \nj.	Conformidad de productos y servicios\nk.	Grado de satisfacción del cliente',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.20. Comunicación interna y externaQHSE relevante incluidos los trabajadores y las partes interesadas externas - visitantes-contratistas en la gestión del cambio - acciones correctivas - informes de incidentes y resultado de la investigación - Política de OHSMS, según corresponda',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.21. Información documentada creación y actualización de documentos - distribución - recuperación - almacenamiento - control de cambios - retención - disposición - control de información documentada de origen externo',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '3.0. Humano Recursos / Entrenamiento',
                            size: "2",
                            hasTexto: true,
                            texto: 'Estructura de la organización - Funciones, responsabilidad, autoridad para los sistemas de gestión de QHSE - Gestión de competencias - Plan de formación y cumplimiento - Concienciación - Vigilancia de la salud - Retención de información documentada',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '4.0. Mantenimiento',
                            size: "4",
                            hasTexto: true,
                            texto: 'a.	Mantenimiento de la infraestructura: retención de información documentada',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Control de seguimiento calibrado o verificado y Equipo de medición para medir el desempeño de QHSE - Revisión de certificados de calibración de equipos y accesorios de elevación, instrumentos de medición y trazabilidad a estándares nacionales- retención de información documentada',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'c.	Inspección de activos, incluido extintor de incendios, incendio. Y detectores de humo, alarma contra incendios, evitando posibles fugas',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'd.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '5.0. Operaciones - Comercial márketing',
                            size: "5",
                            hasTexto: true,
                            texto: 'a.	Revisión de consulta y contrato del cliente - Contrato / identificación del trabajo - cambios en el contrato - retención de información documentada',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Actividades posteriores a la entrega (Garantía - Garantía - Devolución de producto - Reparación - Quejas de clientes)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'c.	Medición y evaluación de cliente satisfacción',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'd.	Revisión de la propiedad suministrada por el cliente (materiales-dibujos-especificaciones)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'e.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            hasCategoria: true,
                            categoria: '6.0. Operaciones - Diseño y desarrollo',
                            size: "3",
                            hasTexto: true,
                            texto: 'a.	Planificación del diseño: revisión de los datos de diseño del cliente y entrada –Diseño del producto / servicio - revisión del diseño - validación del diseño - Calibración y validación del software de diseño, si corresponde - Control de los planos de ingeniería, incluidas las revisiones ',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Propiedad suministrada por el cliente (dibujos y Especificaciones) –aprobación de salida del diseño - revisión del diseño - validación del diseño - verificación del diseño - aprobación del cliente, si es necesario',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'c.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '7.0. Operaciones - Ingeniería',
                            size: "2",
                            hasTexto: true,
                            texto: 'a.	Revisión de dibujos y datos de entrada del cliente - Control de dibujos de ingeniería, incluidas revisiones - propiedad proporcionada por el cliente (dibujos y especificaciones) - revisión de dibujos - retención de información documentada',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '8.0. Operaciones - Obtención',
                            size: "5",
                            hasTexto: true,
                            texto: 'a.	Selección - Evaluación - reevaluación de externos proveedores de productos y servicios teniendo en cuenta los criterios de QHSE–– requisito medioambiental para la adquisición de productos ',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Selección de contratistas considerando QHSE Criterios - identificar peligros, evaluar y controlar los riesgos QHSE que surgen de las actividades y operaciones del contratista y el impacto en la organización del cliente - impacto en los trabajadores de los contratistas - impacto en otras partes interesadas en el lugar de trabajo',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'c.	Control de funciones y procesos subcontratados con el cumplimiento de los requisitos legales y de otro tipo, incluido el tipo de control y el alcance del control',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'd.	Requisito medioambiental para la adquisición de productos',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'e.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '9.0. Operaciones - Historias- preservación',
                            size: "2",
                            hasTexto: true,
                            texto: 'a.	Entorno para tiendas - Control de material incluyendo productos químicos y derrames - Aspecto e impacto ambiental y peligro y riesgo para los procesos de las tiendas - recepción / emisión de materiales - artículos de vida útil - Materiales suministrados por el cliente - Despacho de productos ',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '10.0. Operaciones (obligatorio para cada auditoría)',
                            size: "6",
                            hasSubCheck: true,
                            hasAuditado: true,
                            subCheck: 0,
                            labelSubCheck: 'Auditado durante el horario laboral normal ya que el cliente trabaja solo en turno de día',
                            auditado: '',
                        }, {
                            hasSubCheck: true,
                            subCheck: 0,
                            labelSubCheck: 'Auditado durante el horario laboral normal ya que los procesos son similares en cada turno',
                        }, {
                            hasSubCheck: true,
                            subCheck: 0,
                            labelSubCheck: 'Auditado durante el turno normal y parte del turno considerando diferentes procesos en cada turno',
                        }, {
                            hasTexto: true,
                            texto: 'a.	Planificación y control de la producción: medio ambiente Para la operación del proceso - Validación del proceso - Control de cambios en el proceso',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Transporte o entrega, uso, fin de vida Tratamiento y disposición final de productos suministrados por proveedores externos, incluido el tratamiento al final de su vida útil, si corresponde, para los productos en consideración.',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'c.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '11.0. Producción de operaciones y provisión de servicios en el sitio del Proyecto, si corresponde(obligatorio para cada auditoría)',
                            size: "2",
                            hasTexto: true,
                            texto: 'a.	Sitio temporal del proyecto: \nb.	Planificación y control de operaciones. \nc.	Alcance de los servicios en el sitio - Criterios para el proceso operaciones –competencia- Control y mantenimiento de procesos, incluidos los criterios para la operación del proceso: conocimiento de los requisitos de QHSE por parte de los trabajadores –Inspección de activos como extintores, detectores de humo y fuego, equipos y accesorios de elevación y otros ',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'd.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '12.0. Operaciones - Respuesta a emergencias',
                            size: "2",
                            hasTexto: true,
                            texto: 'a.  Identificación de una posible situación de emergencia en planta y sitio temporal y plan de respuesta',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Planifique la frecuencia del simulacro de simulacro para cada emergencia situación: planificar la capacitación relacionada con la preparación para emergencias',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: '13.0, Garantía de calidad - Control de calidad',
                            size: "4",
                            hasTexto: true,
                            texto: 'a.	Revisar el plan de QHSE / plan de inspección - revisar interfaz con el cliente o el representante del cliente',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Inspección entrante - inspección en proceso - final Inspección del producto - lanzamiento del producto',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'c.	Calibración de recursos de monitoreo y medición - lista de instrumentos - identificación de instrumentos ',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'd.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            detalles: '',
                            resultado: '',
                        },
                    ]
                }],
                c37001: [{
                    body: [{
                            hasCategoria: true,
                            categoria: '1.0. Cima Gestión',
                            size: "1",
                            hasTexto: true,
                            texto: 'Compromiso de gestión \nPropósito de la certificación ABMS \nEfectividad de ABMS \nComunicación interna y externa  \nFiabilidad de la revisión de la gestión y la auditoría interna  \nProvisión de recursos \nPrograma de mejora continua \nDesempeño comercial: otros problemas, si corresponde',
                            detalles: '',
                            resultado: '',
                        },
                        {
                            hasCategoria: true,
                            categoria: '2.0. Función de cumplimiento anti-soborno',
                            size: "16",
                            hasTexto: true,
                            texto: '2.1. Recursos de mano de obra el día de la auditoría (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.2. Revisión del informe de auditoría anterior y cierre de NCR (s) / Observaciones (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.3. Alcance de ABMS (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.4. Política y comunicación ABMS (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.5. ABMSObjectives- logro de objetivos (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.6. Auditoría interna - Planificación de la auditoría - Frecuencia de Auditoría - Criterios de auditoría - Alcance de la auditoría - Informar el resultado de la auditoría a la dirección - Corrección y acción correctiva (obligatorio para cada auditoría) ',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.7. Revisión de gestión - Revisión de gestión frecuencia - Entrada de punto de agenda MRM - Salida MRM y Eficacia del sistema de gestión con respecto a la consecución de los objetivos y los resultados previstos que abarcan,\n•	Revisión de la alta dirección\n•	Revisión de la junta directiva\n•	Revisión de la función de cumplimiento contra el soborno\n(obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.8. Producto-proceso, no conformidad del sistema, Quejas legales y de clientes: acciones correctivas (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.9. Programa de mejora continua (obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.10. Uso de marcas de certificación y acreditación (Obligatorio para cada auditoría)',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.11. Contexto de organización e interesados Fiestas',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.12. Proceso ABMS e información documentada',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.13. Planificación - Riesgos y oportunidades - Evaluación del riesgo de soborno - Eficacia de las acciones para riesgos y oportunidades - Objetivos de ABMS - Planificación para lograr los objetivos - Revisión de cualquier cambio',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.14. Cumplimiento',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: 'a.	Análisis de datos y rendimiento Evaluación \n•	Análisis y evaluación de ABMS  \n•	Objetivos de ABMS \n•	Rendimiento y eficacia del ABMS  \n•	Estado de la cultura de cumplimiento \n•	Necesidad de mejorar ABMS',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasTexto: true,
                            texto: '2.15 Manejo de Documentos',
                            detalles: '',
                            resultado: '',
                        },
                        {
                            hasCategoria: true,
                            categoria: '3.0. Humano Recursos / Entrenamiento',
                            size: "1",
                            hasTexto: true,
                            texto: '3.1 Estructura organizativa, roles, responsabilidad, autoridad - Gestión de competencias, plan de formación y cumplimiento - concienciación- Función de cumplimiento antisoborno: delegación de la toma de decisiones, según corresponda \n(Antes del empleo y durante el empleo, terminación y cambio de empleo)',
                            detalles: '',
                            resultado: '',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Operaciones',
                            size: "1",
                            hasTexto: true,
                            fullWidth: true,
                        },
                        {
                            hasCategoria: true,
                            hasSubCheck: true,
                            subCheck: 0,
                            labelSubCheck: 'Auditoría durante el horario laboral normal, ya que el cliente trabaja solo en el turno de día. ',
                            fullWidth: true,
                        },
                        {
                            hasCategoria: true,
                            hasSubCheck: true,
                            subCheck: 0,
                            labelSubCheck: 'Auditoría durante el horario laboral normal, ya que los procesos son similares en cada turno. ',
                            fullWidth: true,
                        },
                        {
                            hasCategoria: true,
                            hasSubCheck: true,
                            subCheck: 0,
                            labelSubCheck: 'Auditoría realizada durante el turno normal y parte del turno considerando diferentes procesos en cada turno.',
                            fullWidth: true,
                        }, {
                            hasCategoria: true,
                            categoria: 'Revisión del contrato',
                            size: "1",
                            hasTexto: true,
                            texto: '-	Revisión de la consulta y el contrato del cliente - Identificación del número de contrato / trabajo - cambios en el contrato - retención de información documentada.\n-	Actividades posteriores a la entrega / servicio, si las hubiera.\n-	Adecuación del mecanismo de debida diligencia adoptado.\n-	Definición de controles financieros y no financieros',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: 'Administración, Compras - Outsourcing - Gestión de tiendas',
                            size: "1",
                            hasTexto: true,
                            texto: '-	Selección - Evaluación - reevaluación de proveedores externos - Mantener la lista de proveedores aprobados - Subcontratación - Comunicación con agencias externas - Retención de información documentada \n-	Entorno para tiendas - Control de almacenamiento y recepción / emisión de material - Control de artículos de vida útil, si corresponde - Control de propiedad suministrada por el cliente (Materiales) - Retención de información documentada \n-	Adecuación del mecanismo de debida diligencia adoptado. \n-	Controles financieros y no financieros definidos',
                            detalles: '',
                            resultado: '',
                        }, {
                            hasCategoria: true,
                            categoria: 'Proyecto / Diseño o Desarrollo o Ingeniería / Producción o Prestación de Servicios, según corresponda.',
                            size: "1",
                            hasTexto: true,
                            texto: '-	Revisión de los controles antisoborno  \n-	Revisión de los compromisos contra el soborno \n-	Revisión de las políticas de obsequios y hospitalidad \n-	Revisión de políticas para donaciones, organizaciones benéficas, patrocinio, gastos de promoción y beneficios comunitarios. \n-	Revisión de la gestión de la insuficiencia de los controles antisoborno \n-	Planteando preocupaciones \n-	Investigar y lidiar con el soborno',
                            detalles: '',
                            resultado: '',
                        },
                    ]
                }],
                c27001: [{
                    header: [{
                        check: [],
                        options: [{
                                text: 'Oficina registrada',
                                value: '1'
                            },
                            {
                                text: 'Planta',
                                value: '2'
                            },
                            {
                                text: 'Oficina (es) regional (es)',
                                value: '3'
                            },
                            {
                                text: 'Sucursal (es)',
                                value: '4'
                            }
                        ],
                        input1: '',
                        input2: '',
                    }, {
                        texto: 'Visita a la planta',
                        input1: '',
                        input2: '',
                    }, ],
                    body: [{
                            hasCategoria: true,
                            categoria: 'Alta Dirección',
                            auditado: '',
                            size: "13",
                            select: [],
                            hasTexto: true,
                            texto: 'Compromiso de gestión',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasTexto: true,
                            texto: 'Propósito de la certificación SGSI',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Requisitos legales aplicables y actualización de los requisitos',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Alcance de SGSI y SoA (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Política de SI y comunicación (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Objetivos de SI y consecución de objetivos',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Efectividad del SGSI',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Gestión de reclamaciones legales y de clientes (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Comunicación interna y externa',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Fiabilidad de la revisión de la gestión y la auditoría interna',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Provisión de recursos',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Desempeño comercial: otros problemas, si corresponde',
                            detalles: '',
                            check: [],

                        }, {
                            hasCategoria: true,
                            categoria: 'Representante de gestión - CISO',
                            auditado: '',
                            size: "16",
                            select: [],
                            hasTexto: true,
                            texto: 'Recurso de mano de obra el día de la auditoría  (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasSubTexto: true,
                            texto1: '',
                            label1: 'Tiempo completo',
                            placeholder1: 'el tiempo completo',
                            detalles: '',
                            check: [],
                        }, {
                            hasSubTexto: true,
                            texto1: '',
                            label1: 'Mano de obra total',
                            placeholder1: 'la mano de obra total',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Revisión del informe de auditoría anterior y cierre de NCR (s) / Observaciones (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Contexto de la organización y partes interesadas:',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Gestión de la comunicación',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Procesos SGSI e información documentada',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Planificación -  \n•	Riesgos y oportunidades -  \n•	Evaluación de riesgos de SI \n•	Tratamiento de riesgos IS \n•	efectividad de las acciones para riesgos y oportunidades -Objetivos de calidad-\n•	Planificación de los objetivos y el logro de SI (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Planificación de cambios al SGSI',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Análisis de datos y evaluación del desempeño - \na.	Procesos y controles de SI • \nb.	Efectividad de las acciones tomadas para abordar la evaluación de riesgos de SI y el tratamiento de SI • \nc.	Desempeño y efectividad del SGSI• \nd.	Necesidad de mejoras al SGSI',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Auditoría interna - Planificación de la auditoría - Frecuencia de la auditoría - Criterios de auditoría - Alcance de la auditoría - Informe del resultado de la auditoría a la dirección - Corrección y acción correctiva - Retención de información documentada (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Frecuencia de revisión por la dirección - Entrada de puntos de la agenda de MRM - Salida de MRM y Eficacia del sistema de gestión con respecto al logro de los objetivos y los resultados previstos. (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Gestión de la no conformidad del producto / proceso / sistema, Quejas de los clientes - Acción correctiva - Cumplimiento (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales y consecución de objetivos, Corrección y acción correctiva de las observaciones de Auditoría, retención de información documentada',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua considerando resultado de auditoría interna-MRM- resultados de evaluación (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        }, {
                            hasTexto: true,
                            texto: 'Uso de marcas de certificación y acreditación (obligatorio para cada auditoría)',
                            detalles: '',
                            check: [],

                        },
                        {
                            widthRowOne: '2',
                            hasCategoria: true,
                            hasnAuditado: true,
                            hasnSelect: true,
                            hasnTwo: true,
                            categoria: 'Controles IS aplicables para la cláusula  A 5, A 6, A 7, A 8, A 9, A 10, A 11, A 12, A 13, A 14, A 15, A 17, A 18',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Recursos humanos / formación',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Estructura organizativa, roles, responsabilidad, autoridad - Gestión de competencias, plan de formación y cumplimiento - conciencia (Antes del empleo y durante el empleo, terminación y cambio de empleo)',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales de SI, corrección y acción correctiva para observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        },
                        {
                            widthRowOne: '2',
                            hasCategoria: true,
                            hasnAuditado: true,
                            hasnSelect: true,
                            hasnTwo: true,
                            categoria: 'Controles IS aplicables: A 6, A 7, A 9.3, A 17, A 18.1.1 a A 18.1.4',
                            detalles: '',
                            check: [],
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Infraestructura / TI - Red ',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Política de seguridad de la información / Dispositivo móvil y teletrabajo / Gestión de activos / Control de acceso / Criptografía / Equipos / Seguridad de operaciones / Seguridad de las comunicaciones / Adquisición de sistemas / Gestión de incidentes de seguridad de la información / Gestión de la continuidad del negocio / Cumplimiento de todos los controles aplicables a TI, Protección contra malware / volver up / seguimiento de registros / control de instalación de software operativo / vulnerabilidad técnica / eliminación de medios / gestión de capacidad / gestión de cambios / procedimientos operativos / seguridad de la red ',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales de SI, corrección y acción correctiva para observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            widthRowOne: '2',
                            hasCategoria: true,
                            hasnAuditado: true,
                            hasnSelect: true,
                            hasnTwo: true,
                            categoria: 'Controles IS aplicables: A 5, A 6, A 8, A 9, A 10, A 11, A 12, A 13, A 14, A 16, A 17',
                            detalles: '',
                            check: [],
                        },
                        {
                            widthRowOne: '7',
                            hasnTwo: true,
                            hasnDetalles: true,
                            hasCategoria: true,
                            hasnAuditado: true,
                            hasnSelect: true,
                            categoria: 'Operaciones',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Contrato / Marketing',
                            auditado: '',
                            size: "4",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisión de la consulta y el contrato del cliente - Identificación del contrato / trabajo - cambios en el contrato - retención de información documentada ',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Actividades posteriores a la entrega, si las hubiera',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales (SI), corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasCategoria: true,
                            categoria: 'Administración,Compras - Outsourcing - Gestión de tiendas',
                            auditado: '',
                            size: "3",
                            select: [],
                            hasTexto: true,
                            texto: 'Selección - Evaluación - reevaluación de proveedores externos - Mantener la lista de proveedores aprobados - Subcontratación - Comunicación con agencias externas - Retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Entorno para tiendas - Control de almacenamiento y recepción / emisión de material - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales (SI), corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasCategoria: true,
                            categoria: 'Administración,Compras - Outsourcing - Gestión de tiendas',
                            hasnAuditado: true,
                            hasnSelect: true,
                            hasnDetalles: true,
                            hasSubCheck: true,
                            subCheck: 0,
                            labelSubCheck: 'a.	Auditado durante el horario laboral normal ya que el cliente trabaja solo en turno de día',
                            widthRowOne: '2',
                            widthRowTwo: '5',
                            size: "3",
                        }, {
                            hasnDetalles: true,
                            hasSubCheck: true,
                            subCheck: 0,
                            labelSubCheck: 'b.	Auditado durante el horario laboral normal ya que los procesos son similares en cada turno ',
                            widthRowTwo: '5',
                        }, {
                            hasnDetalles: true,
                            hasSubCheck: true,
                            subCheck: 0,
                            labelSubCheck: 'c.	Auditoría realizada durante el turno normal y parte del turno considerando diferentes procesos en cada turno.',
                            widthRowTwo: '5',
                        }, {
                            hasCategoria: true,
                            categoria: 'Proyecto / Diseño o Desarrollo o Ingeniería / Producción o Fabricación / Prestación de servicios',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisión de los datos e insumos del diseño del cliente - Diseño del producto / servicio - calibración y validación del software de diseño, si corresponde - Control de los dibujos de ingeniería, incluidas las revisiones - propiedad suministrada por el cliente (dibujos, especificaciones, prototipos) - aprobación de la salida del diseño - revisión del diseño - validación del diseño - verificación del diseño - aprobación del cliente, si se requiere - liberación de dibujos para producción - retención de información documentada \nRequisitos de seguridad para SI / Evaluación de riesgos de SI / Tratamiento de riesgos de SI / Seguridad en el desarrollo y proceso de soporte, datos de prueba y seguridad',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales - Corrección y acción correctiva para las observaciones de auditoría - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasCategoria: true,
                            categoria: 'Seguro de Calidad / Control de Calidad',
                            auditado: '',
                            size: "3",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisar el plan de control de calidad / plan de inspección - revisar la interfaz con el cliente o el representante del cliente - inspección entrante - inspección en proceso - inspección del producto final - liberación del producto - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Calibración de recursos de monitoreo y medición - lista de instrumentos - identificación de instrumentos - retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales (SI), corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                            detalles: '',
                            check: [],
                        }, {
                            widthRowOne: '2',
                            hasCategoria: true,
                            hasnAuditado: true,
                            hasnSelect: true,
                            hasnTwo: true,
                            categoria: 'Controles IS aplicables: A 6.2, A 8.1, A 8.3, A 9, A 10, A 11, A 12, A 14, A 15, A 16, A 17, A 18',
                            detalles: '',
                            check: [],
                        },
                    ],
                    footer: [{
                        check: [],
                        options: [{
                                text: 'Reunión de cierre en la oficina registrada',
                                value: '1'
                            },
                            {
                                text: 'Ubicación de la planta',
                                value: '2'
                            },
                            {
                                text: 'Oficina regional',
                                value: '3'
                            },
                            {
                                text: 'Sucursal',
                                value: '4'
                            }
                        ]
                    }]
                }],
            },

            //Disabled
            disabledNorma1: false,
            disabledNorma2: false,
            disabledNorma3: false,
            disabledNorma4: false,
            disabledNorma5: false,
            disabledNorma6: false,
            //Listas
            listaClientes: [],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            listaProduccionProceso: [{
                idProduccionProceso: 1,
                descripcion: 'Oficina central',
            }, {
                idProduccionProceso: 2,
                descripcion: 'Planta',
            }, {
                idProduccionProceso: 3,
                descripcion: 'Oficina regional',
            }, {
                idProduccionProceso: 4,
                descripcion: 'Sitio del proyecto',
            }, {
                idProduccionProceso: 5,
                descripcion: 'Sucursal',
            }, {
                idProduccionProceso: 6,
                descripcion: 'Sitio temporal del sitio',
            }],

            listaTipoAuditoria: [{
                idTipoAuditoria: 1,
                descripcion: 'MS individual'
            }, {
                idTipoAuditoria: 2,
                descripcion: 'Auditoría conjunta'
            }, {
                idTipoAuditoria: 3,
                descripcion: 'Auditoría combinada'
            }, {
                idTipoAuditoria: 4,
                descripcion: 'MS integrado'
            }],

            tabIndex: 0,
        }

    },
    methods: {

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        datosGenerales() {
            this.tabIndex += 1;
        },
        seccion1() {
            this.tabIndex += 1;
        },
        seccion2() {
            this.tabIndex += 1;
        },
        seccion3() {
            this.tabIndex += 1;
        },
        seccion4() {
            this.tabIndex += 1;
        },
        anterior() {
            this.tabIndex -= 1;
        },

        checkNormas(e) {
            !!e.find(x => x == 1) ? this.disabledNorma1 = true : this.disabledNorma1 = false;
            !!e.find(x => x == 2) ? this.disabledNorma2 = true : this.disabledNorma2 = false;
            !!e.find(x => x == 3) ? this.disabledNorma3 = true : this.disabledNorma3 = false;
            !!e.find(x => x == 4) ? this.disabledNorma4 = true : this.disabledNorma4 = false;
            !!e.find(x => x == 5) ? this.disabledNorma5 = true : this.disabledNorma5 = false;
            !!e.find(x => x == 6) ? this.disabledNorma6 = true : this.disabledNorma6 = false;
        },

        obtenerCheckList() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/obtener-check-list-s2", {
                    params: {
                        idCheckList: me.$route.params.id,
                        idCliente: me.datosCheckList.idCliente
                    }
                })
                .then(function (response) {
                    me.datosCheckList.idCheckList = response.data[0].idCheckList;
                    me.datosCheckList.idCliente = response.data[0].idCliente;
                    me.datosCheckList.idAuditoria = response.data[0].idAuditoria;
                    me.datosCheckList.normas = JSON.parse(response.data[0].normas);
                                        me.datosCheckList.numeroContrato = response.data[0].numeroContrato;
                    me.datosCheckList.auditoriaActual = response.data[0].auditoriaActual;
                    me.datosCheckList.auditoriaPrevia = response.data[0].auditoriaPrevia;
                    me.datosCheckList.produccion = JSON.parse(response.data[0].produccion);
                    me.datosCheckList.tipoAuditoria = JSON.parse(response.data[0].tipoAuditoria);
                    me.datosCheckList.declaracionAplicabilidad = response.data[0].declaracionAplicabilidad;
                    me.datosCheckList.alcanceCertificacion = response.data[0].alcanceCertificacion;
                    me.datosCheckList.liderEquipoAuditoria = response.data[0].liderEquipoAuditoria;
                    me.datosCheckList.auditor = response.data[0].auditor;
                    me.datosCheckList.indicaciones = JSON.parse(response.data[0].indicaciones);
                    me.datosCheckList.pautas = JSON.parse(response.data[0].pautas);
                    me.datosCheckList.datosEquipoAuditor = JSON.parse(response.data[0].datosEquipoAuditor);
                    me.datosCheckList.c9001 = JSON.parse(response.data[0].c9001);
                    me.datosCheckList.c145001 = JSON.parse(response.data[0].c145001);
                    me.datosCheckList.c37001 = JSON.parse(response.data[0].c37001);
                    me.datosCheckList.c27001 = JSON.parse(response.data[0].c27001);

                    !!me.datosCheckList.normas.find(x => x == 1) ? me.disabledNorma1 = true : me.disabledNorma1 = false;
                    !!me.datosCheckList.normas.find(x => x == 2) ? me.disabledNorma2 = true : me.disabledNorma2 = false;
                    !!me.datosCheckList.normas.find(x => x == 3) ? me.disabledNorma3 = true : me.disabledNorma3 = false;
                    !!me.datosCheckList.normas.find(x => x == 4) ? me.disabledNorma4 = true : me.disabledNorma4 = false;
                    !!me.datosCheckList.normas.find(x => x == 5) ? me.disabledNorma5 = true : me.disabledNorma5 = false;
                    !!me.datosCheckList.normas.find(x => x == 6) ? me.disabledNorma6 = true : me.disabledNorma6 = false;
             
                  if (me.$route.params.eu && me.$route.query.idAuditoria) {
                        me.datosCheckList.isPack = true;
                        me.datosCheckList.idCheckList = me.$route.params.eu == 'u' ? me.$route.params.id : null;
                        me.datosCheckList.idAuditoria = me.$route.query.idAuditoria || null
                        me.datosCheckList.idUsuario = me.$store.state.user.uid;
                        me.datosCheckList.sUsuario = me.$store.state.user.username;
                        me.datosCheckList.eu = me.$route.params.eu == 'u' ? 'u' : 'i'
                    }
             })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                });
        },
        
        registrarCheckList() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/registrar-check-list-s2",
                    me.datosCheckList, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });

        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },

    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosCheckList.idCliente = user.uidClient;
            // this.listarClientes();
            if (this.$route.params.id) {
                this.obtenerCheckList();
            }
        }

    }

}
</script>

<style>
.valign-middle {
    vertical-align: middle !important;
}

.disabledTab {
    pointer-events: none;
    /* background:rgba(37, 37, 37, 0.048); */
}
</style>
